"use client";
import { HomePage } from "@/sanity.types";
import StatsTile from "./StatsTile/StatsTile";

const StatsModule = ({ moduleData }: { moduleData: NonNullable<HomePage["statsModule"]> }) => {
	return (
		<section className="relative pt-[7.5rem] xs:pt-[3.75rem]">
			{moduleData?.map((stat, index) => <StatsTile key={index} tileData={stat} index={index} />)}
		</section>
	);
};

export default StatsModule;
