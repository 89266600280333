import Link from "next/link";
import clsx from "clsx";
import { BtnMainProps } from "./types";

const BtnMain = ({ href, label, className, white, black, type = "button", onClick }: BtnMainProps) => {
	return (
		<>
			{href ? (
				<Link prefetch={false} href={href} className="h-fit w-fit">
					<div
						className={clsx(
							className && className,
							"group relative isolate w-fit min-w-56 overflow-hidden rounded-[1.875rem] font-secondary text-h6 text-white duration-300 sm:w-auto sm:text-h6",
						)}
					>
						<div
							className={clsx(
								white ? "bg-white text-black" : black ? "bg-black text-white" : "bg-blue text-white",
								"flex h-full w-full items-center justify-center whitespace-nowrap rounded-[1.875rem] px-7 py-3 duration-300 ease-out group-hover:w-[calc(100%-3rem)] xs:h-fit",
							)}
						>
							{label}
						</div>
						<div
							className={clsx(
								white ? "bg-white" : black ? "bg-black" : "bg-blue",
								"absolute right-0 top-0 -z-10 flex aspect-square h-full items-center justify-center rounded-full duration-300",
							)}
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
								<path
									d="M8.33335 0.5L14.3334 6.5M14.3334 6.5L8.33335 12.5M14.3334 6.5H0.666687"
									stroke={white ? "black" : "white"}
								/>
							</svg>
						</div>
					</div>
				</Link>
			) : (
				<button
					type={type}
					onClick={onClick}
					className={clsx(
						className && className,
						"group relative isolate w-fit min-w-56 overflow-hidden rounded-[1.875rem] font-secondary text-h6 text-white duration-300",
					)}
				>
					<div
						className={clsx(
							white ? "bg-white text-black" : black ? "bg-black text-white" : "bg-blue text-white",
							"flex h-full w-full items-center justify-center whitespace-nowrap rounded-[1.875rem] px-7 py-3 duration-300 ease-out group-hover:w-[calc(100%-3rem)]",
						)}
					>
						{label}
					</div>
					<div
						className={clsx(
							white ? "bg-white" : black ? "bg-black" : "bg-blue",
							"absolute right-0 top-0 -z-10 flex aspect-square h-full items-center justify-center rounded-full duration-300",
						)}
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
							<path
								d="M8.33335 0.5L14.3334 6.5M14.3334 6.5L8.33335 12.5M14.3334 6.5H0.666687"
								stroke={white ? "black" : "white"}
							/>
						</svg>
					</div>
				</button>
			)}
		</>
	);
};

export default BtnMain;
