"use client";
import { HomePage } from "@/sanity.types";
import BtnMain from "../../../buttons/BtnMain/BtnMain";
import { useRef } from "react";
import { useInView } from "framer-motion";
import clsx from "clsx";

const VideoModule = ({ moduleData, lng }: { moduleData: HomePage["videoModule"]; lng: string }) => {
	const ref = useRef<HTMLDivElement>(null);
	const isInView = useInView(ref, { amount: 0.3, once: true });

	return (
		<section
			ref={ref}
			className={clsx(
				isInView ? "translate-y-0 opacity-100" : "translate-y-20 opacity-0",
				"relative isolate mx-grid-s flex aspect-[9250/4825] items-center justify-center overflow-hidden rounded-[1.25rem] duration-500 sm:aspect-video xs:mx-grid-xs xs:my-[3.75rem] xs:aspect-[9/16]",
			)}
		>
			<video playsInline loop muted autoPlay className="absolute -z-10 h-full w-full object-cover xs:hidden">
				<source src={moduleData?.dekstopBgVideoUrl} type="video/mp4" />
			</video>
			<video playsInline loop muted autoPlay className="absolute -z-10 hidden h-full w-full object-cover xs:block">
				<source src={moduleData?.mobileBgVideoUrl} type="video/mp4" />
			</video>

			<div className="flex w-7/12 flex-col items-center justify-center gap-y-10 text-center sm:w-10/12 xs:w-11/12">
				<h3 className="text-h3 text-[#F6F5FF] xs:text-h2-xs">{moduleData?.title}</h3>
				<BtnMain white href={`/${lng}/${moduleData?.cta?.url ?? "/"}`} label={moduleData?.cta?.label ?? "See more"} />
			</div>
			<div
				className={clsx(isInView ? "bottom-full" : "bottom-0", "absolute left-0 h-full w-full bg-gray-200 delay-500 duration-500")}
			></div>
		</section>
	);
};

export default VideoModule;
