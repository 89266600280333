"use client";
import { HomePage } from "@/sanity.types";
import Slide from "./Slide/Slide";
import { useEffect, useRef, useState } from "react";
import { useSpring, motion, useInView } from "framer-motion";
import { useKeenSlider } from "keen-slider/react";
import arrow from "@assets/images/keen_mobile_arrow.svg";
import Image from "next/image";
import clsx from "clsx";

const Functions = ({ moduleData }: { moduleData: NonNullable<HomePage["functionsModule"]> }) => {
	const objectRef = useRef<HTMLDivElement>(null);
	const parentRef = useRef<HTMLDivElement>(null);
	const [slideWidth, setSlideWidth] = useState(0);
	const [parentTop, setParentTop] = useState(0);
	const [rootFontSize, setRootFontSize] = useState<number>(0);
	const x = useSpring(0, { stiffness: 120, damping: 20, mass: 1.25 });
	const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>();

	const [activeSlide, setActiveSlide] = useState(0);
	const sliderParentRef = useRef<HTMLDivElement>(null);

	const elemRef = useRef<HTMLDivElement>(null);
	const isDesktopInView = useInView(elemRef, { amount: 0.5, once: true });

	useEffect(() => {
		instanceRef.current?.on("slideChanged", (slide) => {
			setActiveSlide(slide.track.details.abs);
		});
	}, [instanceRef]);

	useEffect(() => {
		instanceRef.current?.moveToIdx(activeSlide);
	}, [activeSlide]);

	useEffect(() => {
		const getSlideWidth = () => {
			const slide = document.getElementById("slide-0");
			if (slide && slide.offsetWidth > 0) {
				setSlideWidth(slide.offsetWidth);
			} else {
				requestAnimationFrame(getSlideWidth);
			}
		};

		window.addEventListener("resize", getSlideWidth);
		getSlideWidth(); // Initial call to set the slide width
		return () => {
			window.removeEventListener("resize", getSlideWidth);
		};
	}, []);

	useEffect(() => {
		if (typeof window !== "undefined") {
			setRootFontSize(parseFloat(getComputedStyle(document.body).fontSize));
		}
	}, []);

	useEffect(() => {
		if (slideWidth > 0) {
			const distanceFromParentTop = () => {
				if (parentRef.current && objectRef.current) {
					const parentTop = parentRef.current.getBoundingClientRect().top;
					x.set(
						parentTop < 0
							? Math.max(
									-1 * (slideWidth + 20) * (moduleData?.functionsSlider?.length ?? 0) +
										(window.innerWidth - 10.5 * rootFontSize),
									parentTop,
								)
							: 0,
					);
					setParentTop(parentTop);
				}
			};

			distanceFromParentTop();
			document.addEventListener("scroll", distanceFromParentTop);

			return () => {
				document.removeEventListener("scroll", distanceFromParentTop);
			};
		}
	}, [slideWidth]);

	return (
		<>
			<section
				ref={parentRef}
				className="relative pt-20 xs:hidden"
				style={{
					minHeight: `${moduleData?.functionsSlider ? (slideWidth + 20) * (moduleData.functionsSlider.length - 1) * 1.35 : 0}px`,
				}}
			>
				<div
					ref={elemRef}
					className={clsx(
						isDesktopInView ? "translate-y-0 opacity-100" : "translate-y-20 opacity-0",
						"sticky top-[15%] overflow-hidden duration-500",
					)}
				>
					<h3 className="px-grid text-h3">{moduleData?.title}</h3>
					<motion.div style={{ x }} ref={objectRef} className="mt-[3.69rem] flex w-max gap-x-[1.38rem] pb-8 pl-grid">
						{moduleData?.functionsSlider?.map((slide, index) => (
							<Slide
								// onClick={() => handleSlideClick(index)}
								id={`slide-${index}`}
								key={index}
								slideData={slide}
								i={index}
								activeIndex={Math.max(
									0,
									Math.min(
										(moduleData?.functionsSlider?.length ?? 0) - 1,
										Math.floor(-(parentTop / (slideWidth + 20) - 1)),
									),
								)}
							/>
						))}
					</motion.div>
				</div>
			</section>

			{/* Mobile Slider */}
			<section ref={sliderParentRef} className="relative hidden xs:block">
				<div className="overflow-hidden">
					<h3 className="w-11/12 px-grid-xs text-h2-xs">{moduleData?.title}</h3>
					<div ref={sliderRef} className="keen-slider">
						{moduleData?.functionsSlider?.map((slide, index) => (
							<div key={index} className="keen-slider__slide px-grid-xs pb-8 pt-6">
								<Slide id={`slide-${index}`} slideData={slide} i={index} activeIndex={activeSlide} />
							</div>
						))}
					</div>
					<div className="mt-[2.3rem] flex items-center justify-between px-grid-xs pb-1">
						{/* Arrow left */}
						<button
							type="button"
							onClick={() => setActiveSlide((prev) => Math.max(0, prev - 1))}
							className={clsx(activeSlide == 0 && "pointer-events-none opacity-0", "duration-300")}
						>
							<Image src={arrow.src} height={arrow.height} width={arrow.width} alt="Previous Slide" className="rotate-180" />
						</button>

						{/* Slider indicator */}
						<div className="relative h-px w-7/12 bg-[#C1C1C1]">
							<div
								className="h-2 rounded-[0.625rem] bg-blue duration-300"
								style={{
									width: `${100 / moduleData?.functionsSlider?.length}%`,
									transform: `translate(${activeSlide * 100}%, -50%)`,
								}}
							></div>
						</div>

						{/* Arrow right */}
						<button
							type="button"
							onClick={() => setActiveSlide((prev) => Math.min(moduleData?.functionsSlider?.length - 1, prev + 1))}
							className={clsx(
								activeSlide == moduleData?.functionsSlider?.length - 1 && "pointer-events-none opacity-0",
								"duration-300",
							)}
						>
							<Image src={arrow.src} height={arrow.height} width={arrow.width} alt="Next Slide" />
						</button>
					</div>
				</div>
			</section>
		</>
	);
};

export default Functions;
