"use client";
import { CaseCategory, HomePage } from "@/sanity.types";
import BuildingTypeTile from "./BuildingTypeTile/BuildingTypeTile";
import Image from "next/image";
import dots from "@assets/images/dots.svg";
import { useRef } from "react";
import { useInView } from "framer-motion";
import clsx from "clsx";

const BuildingTypes = ({ moduleData, lng }: { moduleData: HomePage["buildingTypesModule"]; lng: string }) => {
	const ref = useRef<HTMLDivElement>(null);
	const isInView = useInView(ref, { amount: 0.5, once: true });

	return (
		<section ref={ref} className="sm:max-w-screen relative my-40 xs:my-[3.75rem] xs:overflow-hidden">
			<Image
				src={dots.src}
				alt="Background dots"
				width={dots.width}
				height={dots.height}
				className="absolute left-1/2 top-0 -translate-x-1/2 opacity-50 xs:hidden"
			/>
			<div
				className={clsx(
					isInView ? "translate-y-0 opacity-100" : "translate-y-20 opacity-0",
					"mx-grid flex justify-center pt-24 duration-500 xs:mx-grid-xs xs:pt-9",
				)}
			>
				<h3 className="text-h3 xs:text-center xs:text-h2-xs">{moduleData?.title}</h3>
			</div>
			<div
				className={clsx(
					"mt-[4.5rem] flex shrink-0 grow-0 flex-wrap justify-center gap-x-5 gap-y-12 sm:mx-grid sm:grid sm:grid-cols-3 sm:gap-x-3 sm:gap-y-12 xs:mx-grid-xs xs:mt-9 xs:pb-12",
				)}
			>
				{moduleData?.buildingTypes?.map((buildingType, index) => (
					<BuildingTypeTile
						tileData={buildingType as unknown as CaseCategory}
						key={"building" + index}
						index={index}
						lng={lng}
						className="max-w-full"
					/>
				))}
			</div>
		</section>
	);
};

export default BuildingTypes;
