"use client";
import step1 from "@assets/lottie/step_1.json";
import step2 from "@assets/lottie/step_2.json";
import step3 from "@assets/lottie/step_3.json";
import step4 from "@assets/lottie/step_4.json";

import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { LottieRefCurrentProps } from "lottie-react";
import { useEffect, useRef } from "react";
import dynamic from "next/dynamic";
const DynamicLottie = dynamic(() => import("lottie-react"), { ssr: false });

const StepTile = ({
	tileData,
	index,
	isActive,
	imgContainerClass,
	scrollYProgress,
	slidesQty,
}: {
	tileData: string;
	index: number;
	isActive: boolean;
	imgContainerClass?: string;
	scrollYProgress: number;
	slidesQty: number;
}) => {
	const lottieRef = useRef<LottieRefCurrentProps | null>(null);
	// const parentRef = useRef<HTMLDivElement>(null);
	// const isInView = useInView(parentRef, { amount: 0.5, once: true });

	useEffect(() => {
		lottieRef.current?.stop();
		if (isActive) {
			lottieRef.current?.play();
		} else {
			lottieRef.current?.stop();
		}
	}, [isActive]);

	const getLottieToIndex = (index: number) => {
		switch (index) {
			case 0:
				return step1;
			case 1:
				return step2;
			case 2:
				return step3;
			case 3:
				return step4;
			default:
				return step1;
		}
	};

	const defineOffsetClass = (index: number) => {
		switch (index) {
			case 0:
				return "-mt-8";
			case 1:
				return "translate-x-10";
			case 2:
				return "p-6 -mt-2";
			case 3:
				return "-mt-8";
			default:
				return "-mt-10";
		}
	};

	return (
		<div
		// ref={parentRef}
		// className={clsx(isInView ? "translate-y-0 opacity-100" : "translate-y-20 opacity-0", "duration-500")}
		// style={{ transitionDelay: `${index * 100}ms` }}
		>
			<div
				className={clsx(
					isActive ? "border-blue bg-white shadow-blueish" : "border-[#D9D9D9]",
					"mx-[3rem]",
					"relative aspect-square min-w-24 rounded-[1.25rem] border duration-500",
				)}
			>
				<DynamicLottie
					autoPlay={false}
					lottieRef={lottieRef}
					animationData={getLottieToIndex(index)}
					className={clsx(imgContainerClass, defineOffsetClass(index), "aspect-square max-h-full")}
				/>
			</div>
			<div className="relative pl-12">
				<div
					className={clsx(
						isActive ? "bg-blue" : "bg-gray-400",
						"relative z-10 mt-9 flex aspect-square h-10 items-center justify-center rounded-full text-white duration-500",
					)}
				>
					{index + 1}
				</div>
				<div
					className={clsx(
						index == 0 ? "left-12" : "left-0",
						index + 1 == slidesQty ? "right-[3.25rem]" : "right-0",
						"absolute bottom-5 h-px bg-blue ease-in-out",
					)}
					style={{ transformOrigin: "left", transform: `scaleX(${Math.min(scrollYProgress / 100, 1)})` }}
				></div>
				<AnimatePresence>
					{index + 1 == slidesQty && scrollYProgress / 100 > 0.95 && (
						<motion.div
							layout
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.5 }}
							className="absolute bottom-5 right-10 flex aspect-square h-5 translate-y-1/2 items-center justify-center rounded-full bg-black"
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" fill="none">
								<path d="M1 3.30769L4.42857 7L10 1" stroke="#F3F3F3" strokeWidth="2" />
							</svg>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
			<div className="mt-9 h-16">
				<AnimatePresence>
					{isActive && (
						<motion.p
							layout
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 20 }}
							transition={{ duration: 0.5 }}
							className={clsx("px-12 text-base")}
						>
							{tileData}
						</motion.p>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
};

export default StepTile;
