import { PortableText } from "next-sanity";

const TurnEmToBlue = ({ children }: { children: React.ReactNode }) => {
	return <span className="text-blue">{children}</span>;
};

const colorBlueComponent = {
	marks: {
		em: ({ children }: { children: string }) => <TurnEmToBlue>{children}</TurnEmToBlue>,
	},
	block: {
		normal: ({ children }: { children: string }) => children,
	},
};
// @ts-expect-error - TS doesn't like the fact that we're passing a custom component
export const PortableToBlue = ({ value }) => {
	// @ts-expect-error - TS doesn't like the fact that we're passing a custom component
	return <PortableText value={value} components={colorBlueComponent} />;
};
