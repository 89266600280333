"use client";
import { HomePage } from "@/sanity.types";
import bgImg from "@assets/images/bb_hero_bg.jpg";
import { PortableToBlue } from "../../../utility/richTextComponents/richTextComponents";
import BtnMain from "../../../buttons/BtnMain/BtnMain";
import Image from "next/image";
import VideoContainer from "./VideoContainer/VideoContainer";
import bgImg_pl from "@assets/images/hero_illustration_PL_cover.jpg";
import bgImg_en from "@assets/images/hero_illustration_EN_cover.jpg";

const Header = ({ moduleData, lng }: { moduleData: HomePage["header"]; lng: string }) => {
	return (
		<section className="relative z-10 grid h-svh w-full grid-cols-14 items-center bg-[#EFEFEF] px-grid sm:grid-cols-2 sm:grid-rows-[1fr_min-content] sm:px-grid-s xs:gap-x-5 xs:px-grid-xs xs:pt-16">
			<Image
				src={bgImg.src}
				alt="background"
				fill
				style={{ objectFit: "cover" }}
				className="absolute -z-10 h-full w-full mix-blend-multiply"
			/>
			<div className="col-span-7 flex flex-col gap-y-14 sm:col-span-full sm:pb-24 xs:mt-6 xs:gap-y-12 xs:pb-0">
				{moduleData && (
					<h1 className="text-h1 sm:text-center sm:text-h2 xs:text-start xs:text-h1-xs">
						<PortableToBlue value={moduleData.title} />
					</h1>
				)}
				<BtnMain href={`/${lng}/${moduleData?.cta?.url}` || "/"} label={moduleData?.cta?.label || "Learn More"} className="mb-12" />
			</div>
			<div className="relative -z-20 col-span-7 col-start-8 flex items-center sm:col-span-full sm:col-start-1 sm:row-start-1 sm:pt-10 xs:h-auto xs:pt-0">
				{lng == "pl" ? (
					<VideoContainer
						url="https://res.cloudinary.com/duy6eu3gt/video/upload/f_auto/hero_illustration_PL_fl8ltw_fmrm4b"
						fallback={bgImg_pl}
					/>
				) : (
					<VideoContainer
						url="https://res.cloudinary.com/duy6eu3gt/video/upload/f_auto/hero_illustration_EN_gxweiv_cztnez"
						fallback={bgImg_en}
					/>
				)}
			</div>
		</section>
	);
};

export default Header;
