import { HomePage } from "@/sanity.types";

const TestimonialTile = ({ tileData }: { tileData: NonNullable<NonNullable<HomePage["testimonialsModule"]>["testimonials"]>[number] }) => {
	return (
		<div className="flex flex-col justify-between gap-y-14 rounded-[1.25rem] bg-white px-7 py-8 text-base-s">
			<p className="font-secondary text-[#0E0A30]">{tileData?.text}</p>
			<div className="flex flex-col leading-[130%]">
				<p className="font-secondary-bold">{tileData.author}</p>
				<p className="font-secondary opacity-50">{tileData.position}</p>
			</div>
		</div>
	);
};

export default TestimonialTile;
