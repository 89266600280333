"use client";
import { PortableText } from "next-sanity";
import BtnMain from "../../../../buttons/BtnMain/BtnMain";
import { PortableToBlue } from "../../../../utility/richTextComponents/richTextComponents";
import { BlueTextBlock, HomePage } from "@/sanity.types";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useInView } from "framer-motion";
import clsx from "clsx";

type feature = NonNullable<HomePage["features"]>[number];

const DesktopDescBlock = ({
	title,
	description,
	cta,
	lng,
	index,
	setActiveDesktopIndex,
}: {
	title?: BlueTextBlock;
	description?: feature["description"];
	cta?: feature["cta"];
	lng: string;
	index: number;
	setActiveDesktopIndex: Dispatch<SetStateAction<number>>;
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const isInView = useInView(ref, { amount: 0.8 });

	useEffect(() => {
		if (isInView) {
			setActiveDesktopIndex(index);
		}
	}, [isInView]);

	return (
		<div
			ref={ref}
			className={clsx(
				isInView ? "translate-x-0 opacity-100" : "translate-x-20 opacity-0",
				"flex flex-col gap-y-[3.125rem] duration-[500ms]",
			)}
		>
			<h2 className="scroll-header text-h2">{title && <PortableToBlue value={title} />}</h2>
			<div className="font-secondary [&_li]:text-list [&_p]:min-h-4 [&_p]:text-base [&_p]:text-gray-700 [&_ul]:list-inside [&_ul]:list-disc [&_ul]:text-gray-700">
				{description && <PortableText value={description} />}
			</div>
			{cta && <BtnMain href={`/${lng}/${cta.url}`} label={cta?.label ?? ""} />}
		</div>
	);
};

export default DesktopDescBlock;
