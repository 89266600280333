"use client";
import { HomePage } from "@/sanity.types";
import { PortableToBlue } from "../../../utility/richTextComponents/richTextComponents";
import Link from "next/link";
import Image from "next/image";
import { urlFor } from "@/sanity/lib/image";
import animation from "@assets/lottie/download.json";
import clsx from "clsx";
import { useRef } from "react";
import { useInView } from "framer-motion";
import dynamic from "next/dynamic";
const DynamicLottie = dynamic(() => import("lottie-react"), { ssr: false });

const Download = ({ moduleData, className }: { moduleData: HomePage["appDownloadModule"]; className?: string }) => {
	const ref = useRef<HTMLDivElement>(null);
	const isInView = useInView(ref, { amount: 0.5, once: true });

	return (
		<section
			className={clsx(
				className && className,
				"grid grid-cols-14 px-grid sm:px-grid-s xs:relative xs:z-10 xs:grid-cols-1 xs:px-grid-xs",
			)}
		>
			<div
				ref={ref}
				className={clsx(
					isInView ? "translate-y-0 opacity-100" : "translate-y-20 opacity-0",
					"col-span-8 col-start-4 mb-20 mt-32 flex max-h-[24rem] gap-x-[4.2rem] rounded-[1.25rem] bg-gray-100 pl-[3.125rem] pr-3 pt-[3.125rem] duration-500 sm:col-span-12 sm:col-start-2 sm:max-h-none xs:col-span-full xs:col-start-1 xs:px-5 xs:py-10",
				)}
			>
				<div>
					<h3 className="w-5/12 text-h3 xs:w-full xs:text-h2-xs">
						<PortableToBlue value={moduleData?.title} />
					</h3>
					<p className="mt-[1.88rem] w-10/12 font-secondary text-base-s text-gray-700 xs:mt-3 xs:w-full">
						{moduleData?.description}
					</p>
					<div className="relative mt-10 hidden xs:block">
						{isInView && <DynamicLottie animationData={animation} className="h-full max-w-full" />}
					</div>
					<div className="mb-[3.37rem] mt-[3.63rem] flex gap-x-[0.62rem] sm:justify-center xs:mb-0">
						<Link
							prefetch={false}
							href={moduleData?.appDownloadLinks?.googlePlay?.url || "/"}
							className="overflow-hidden rounded-l duration-500 hover:scale-95"
						>
							<Image
								src={
									moduleData?.appDownloadLinks?.googlePlay?.icon
										? urlFor(moduleData.appDownloadLinks.googlePlay.icon).url()
										: "/images/googlePlay.svg"
								}
								alt="Google Play"
								width={175}
								height={52}
							/>
						</Link>
						<Link
							prefetch={false}
							href={moduleData?.appDownloadLinks?.appStore?.url || "/"}
							className="overflow-hidden rounded-l duration-500 hover:scale-95"
						>
							<Image
								src={
									moduleData?.appDownloadLinks?.appStore?.icon
										? urlFor(moduleData.appDownloadLinks.appStore.icon).url()
										: "/images/appStore.svg"
								}
								alt="App Store"
								width={179}
								height={52}
							/>
						</Link>
					</div>
				</div>
				<div className="relative -mr-12 -mt-20 h-[120%] w-full sm:mr-0 xs:hidden">
					<DynamicLottie animationData={animation} className="h-full max-w-full" />
				</div>
			</div>
		</section>
	);
};

export default Download;
