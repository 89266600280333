"use client";
import { urlFor } from "@/sanity/lib/image";
import clsx from "clsx";
import Image from "next/image";
import { getImageDimensions } from "@sanity/asset-utils";
import { useRef } from "react";
import { SlideProps } from "./types";
import { useInView } from "framer-motion";
import { SanityImageAsset } from "@/sanity.types";

const Slide = ({ slideData, className, i, activeIndex, id }: SlideProps) => {
	const ref = useRef(null);
	const isInView = useInView(ref, { amount: 0.5, once: true });

	return (
		<div
			// onClick={onClick}
			id={id}
			ref={ref}
			className={clsx(isInView ? "translate-y-0 opacity-100" : "translate-y-20 opacity-0", className && className, "duration-500")}
			// style={{ transitionDelay: `${i * 0.1}s` }}
		>
			<div
				className={clsx(
					activeIndex == i ? "border-blue shadow-blueish" : "border-gray-300",
					"relative flex aspect-[5/6] max-w-[25rem] shrink-0 flex-col justify-between rounded-[1.25rem] border p-10 duration-500 xs:aspect-[11/15] xs:max-w-full",
				)}
				// style={{ transitionDelay: `${i * 0.1}s` }}
			>
				<div className="flex flex-col gap-y-[1.87rem]">
					<div className="text-h5">{slideData?.title}</div>
					<p className="text-base-s text-gray-700">{slideData?.description}</p>
				</div>
				<div
					className={clsx(
						activeIndex == i ? "bg-blue" : "bg-gray-300",
						"flex aspect-square w-10 items-center justify-center rounded-full text-base text-white duration-500",
					)}
				>
					{i + 1}
				</div>
				<Image
					className="absolute bottom-4 right-4"
					src={urlFor(activeIndex == i ? slideData?.hoverIcon : slideData?.icon).url()}
					height={
						getImageDimensions(
							activeIndex == i
								? (slideData?.hoverIcon as unknown as SanityImageAsset)
								: (slideData?.icon as unknown as SanityImageAsset),
						).height
					}
					width={
						getImageDimensions(
							activeIndex == i
								? (slideData?.hoverIcon as unknown as SanityImageAsset)
								: (slideData?.icon as unknown as SanityImageAsset),
						).width
					}
					alt={slideData?.title || ""}
				/>
			</div>
		</div>
	);
};

export default Slide;
