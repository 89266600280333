"use client";
import { HomePage } from "@/sanity.types";
import TestimonialTile from "../TestimonialTile/TestimonialTile";
import { motion, useTransform, MotionValue, useSpring } from "framer-motion";

const TestimonialsWrapper = ({
	testimonials,
	scrollYProgress,
}: {
	testimonials: NonNullable<HomePage["testimonialsModule"]>["testimonials"];
	scrollYProgress: MotionValue<number>;
}) => {
	function useParallax(value: MotionValue<number>, distance: number) {
		return useTransform(value, [0, 1], [-distance, distance]);
	}

	function useSpringSmoothing(value: MotionValue<number>) {
		return useSpring(value, { stiffness: 120, damping: 20, mass: 1.25 });
	}

	return (
		<div className="-mr-12 mt-20 flex max-h-full basis-1/2 gap-5">
			<motion.div style={{ y: useSpringSmoothing(useParallax(scrollYProgress, -200)) }} id="col-1" className="flex flex-col gap-y-5">
				{testimonials &&
					testimonials.map((testimonial, index: number) => {
						if (index < 2) return <TestimonialTile key={index} tileData={testimonial} />;
					})}
			</motion.div>
			<motion.div
				style={{ y: useSpringSmoothing(useParallax(scrollYProgress, 200)) }}
				id="col-2"
				className="mt-14 flex flex-col gap-y-5"
			>
				{testimonials &&
					testimonials.map((testimonial, index: number) => {
						if (index > 1) return <TestimonialTile key={index} tileData={testimonial} />;
					})}
			</motion.div>
		</div>
	);
};

export default TestimonialsWrapper;
