"use client";
import { HomePage } from "@/sanity.types";
import { PortableToBlue } from "../../../utility/richTextComponents/richTextComponents";
import StepTile from "./StepTile/StepTile";
import { useEffect, useRef, useState } from "react";
import StepMobileTile from "./StepMobileTile/StepMobileTile";
import { useInView } from "framer-motion";

const DemoModule = ({ moduleData }: { moduleData: HomePage["demoModule"] }) => {
	const parentRef = useRef<HTMLDivElement>(null);
	const mobileParentRef = useRef<HTMLDivElement>(null);
	const elemRef = useRef<HTMLDivElement>(null);
	const [activeStep, setActiveStep] = useState(0);
	const [scrollY, setScrollY] = useState(0);
	const isInView = useInView(parentRef, { amount: 0.75 });

	// useEffect(() => {
	// 	const calculateElemTopFromParent = () => {
	// 		if (parentRef.current && elemRef.current) {
	// 			const parentRect = parentRef.current.getBoundingClientRect();
	// 			const elemRect = elemRef.current.getBoundingClientRect();

	// 			const elemTop = elemRect.top - parentRect.top;
	// 			return elemTop;
	// 		}
	// 		return 0;
	// 	};

	// 	const handleScroll = () => {
	// 		if (parentRef.current && elemRef.current) {
	// 			const elemTop = calculateElemTopFromParent();
	// 			setActiveStep(Math.floor(elemTop / 400 - 1));
	// 			console.log("elemTop", elemTop);
	// 			setScrollY(elemTop);
	// 		}
	// 	};

	// 	window.addEventListener("scroll", handleScroll);

	// 	return () => {
	// 		window.removeEventListener("scroll", handleScroll);
	// 	};
	// }, []);

	useEffect(() => {
		let timer: string | number | NodeJS.Timeout | undefined;
		if (isInView) {
			const now = Date.now();
			timer = setInterval(() => {
				// console.log(-(now - Date.now()) / 20);
				setActiveStep(Math.floor(-(now - Date.now()) / 1500));
				setScrollY(-(now - Date.now()) / 15);
			}, 10);
		} else {
			setActiveStep(0);
			setScrollY(0);
			if (timer) {
				clearInterval(timer);
			}
		}

		return () => {
			if (timer) {
				clearInterval(timer);
			}
		};
	}, [isInView]);

	return (
		<>
			{/* Desktop version */}
			<section
				ref={parentRef}
				className="relative min-h-max bg-[#F3F3F3] px-grid-s pb-36 sm:hidden"
				// style={{ height: `${(moduleData?.steps?.length || 4) * 520 + vHeight}px` }}
			>
				<div ref={elemRef} className="flex flex-col items-center">
					<div className="w-4/12 px-5">
						<h3 className="whitespace-pre-wrap pt-36 text-center text-h3">
							<PortableToBlue value={moduleData?.title} />
						</h3>
					</div>
					<div className="relative mt-24 grid w-full grid-cols-4">
						{moduleData?.steps?.map((step, index) => (
							<StepTile
								key={index}
								tileData={step}
								index={index}
								isActive={isInView ? index <= Math.min(activeStep, (moduleData.steps?.length ?? 0) - 1) : false}
								scrollYProgress={Math.max(0, scrollY - index * 100)}
								slidesQty={moduleData.steps?.length || 4}
							/>
						))}
					</div>
				</div>
			</section>

			{/* Mobile version */}
			<section
				ref={mobileParentRef}
				className="hidden min-h-screen bg-[#F3F3F3] pb-36 sm:block sm:px-[8rem] xs:px-grid-xs"
				// style={{ height: `${moduleData?.steps?.length * 520 + vHeight}px` }}
			>
				<div ref={elemRef} className="flex flex-col items-center">
					<div className="flex justify-center">
						<h3 className="w-10/12 whitespace-pre-wrap pt-36 text-center text-h2 xs:text-h2-xs">
							<PortableToBlue value={moduleData?.title} />
						</h3>
					</div>
					<div className="relative mt-24 grid w-full grid-cols-1">
						{moduleData?.steps?.map((step, index) => <StepMobileTile key={index} tileData={step} index={index} />)}
					</div>
				</div>
			</section>
		</>
	);
};

export default DemoModule;
