"use client";
import { useTranslation } from "@/app/i18n/client";
import clsx from "clsx";
import { useEffect, useState } from "react";

const FloatingContactBtn = ({ lng }: { lng: string }) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const { t } = useTranslation(lng);

	useEffect(() => {
		const updateScrollPercent = () => {
			const scrollPercent = window.scrollY / document.body.scrollHeight;
			setVisibility(scrollPercent);
		};

		const setVisibility = (scrollPercent: number) => {
			if (scrollPercent > 0.2) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", updateScrollPercent);

		return () => {
			window.removeEventListener("scroll", updateScrollPercent);
		};
	}, []);

	return (
		<div
			onClick={() => {
				const footerElement = document.getElementById("footer");
				if (footerElement) {
					window.scrollTo({
						top: footerElement.offsetTop - 50,
						behavior: "smooth",
					});
				}
			}}
			className={clsx(
				isVisible ? "bottom-6 opacity-100" : "-bottom-16 opacity-0",
				"fixed right-20 z-40 flex h-[2.8rem] cursor-pointer items-center justify-center rounded-[1.875rem] border border-blue bg-blue px-5 text-white duration-500 hover:bg-white hover:text-blue",
			)}
		>
			{t("utilities.interested")}
		</div>
	);
};

export default FloatingContactBtn;
