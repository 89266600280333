"use client";
import { HomePage, SanityImageAsset } from "@/sanity.types";
import { urlFor } from "@/sanity/lib/image";
import Image from "next/image";
import { getImageDimensions } from "@sanity/asset-utils";
import dots from "@assets/images/stats_dots.svg";
import { SanityImageSource } from "@sanity/asset-utils";

const StatsTile = ({ tileData, index }: { tileData: NonNullable<HomePage["statsModule"]>[number]; index: number }) => {
	return (
		<>
			<div className="sticky top-[7.5rem] ml-56 aspect-[4162/2775] w-[40%] before:absolute before:-left-10 before:-right-10 before:-top-80 before:inline-block before:h-96 before:bg-gradient-to-t before:from-white-50 before:to-transparent sm:ml-grid-s sm:w-[50%] xs:top-[7.5rem] xs:float-right xs:ml-0 xs:mr-grid-xs xs:mt-[3.75rem] xs:aspect-square xs:w-8/12 xs:before:right-0">
				<div className="relative h-full w-full">
					<Image
						src={urlFor(tileData.bgImage as unknown as SanityImageAsset).url()}
						alt={tileData?.bgImage?.alt || ""}
						fill
						className="rounded-[1.25rem]"
						style={{ objectFit: "cover" }}
						sizes="(max-width: 600px) 90vw, 50vw"
					/>
				</div>
				{index == 0 && (
					<Image
						src={dots.src}
						alt="Background dots"
						width={dots.width}
						height={dots.height}
						className="absolute -left-1/2 top-32 -z-10"
					/>
				)}
			</div>
			<div className="relative z-10 mx-grid mb-[12.5rem] mt-12 flex items-end justify-between sm:mx-grid-s xs:mx-0 xs:my-0 xs:w-full xs:max-w-[100vw] xs:-translate-y-48 xs:flex-col xs:items-start xs:gap-y-16 xs:overflow-hidden xs:px-grid-xs">
				<div className="rounded-[1.25rem] bg-white-50 p-5 backdrop-blur-[15px]">
					<p className="font-primary-digits text-digits sm:text-digits-sm xs:text-digits-xs">{tileData.value}</p>
				</div>
				<div className="flex w-1/3 flex-col gap-y-[3.12rem] sm:w-[40%] xs:w-full">
					<Image
						src={urlFor(tileData.icon).url()}
						alt={tileData.title || ""}
						width={getImageDimensions(tileData.icon as SanityImageSource).width}
						height={getImageDimensions(tileData.icon as SanityImageSource).height}
					/>
					<div className="flex flex-col gap-y-4 xs:w-9/12 xs:rounded-[1.25rem] xs:bg-white-50 xs:py-5 xs:pr-4 xs:backdrop-blur-[15px]">
						<div className="w-[63%] font-secondary text-h5 xs:w-full xs:text-h5-xs">{tileData.title}</div>
						<p className="w-10/12 font-secondary text-base text-gray-700 sm:w-full xs:text-base-s">{tileData.description}</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default StatsTile;
