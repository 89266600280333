"use client";
import { useRef, useState } from "react";
import { BuildingTypeTileProps } from "./types";
import { urlFor } from "@/sanity/lib/image";
import Image from "next/image";
import Link from "next/link";
import clsx from "clsx";
import { AnimatePresence, motion, useInView } from "framer-motion";
import caseCategory from "@/sanity/schemaTypes/schemas/utils/caseCategory";

const BuildingTypeTile = ({ tileData, className, index, lng }: BuildingTypeTileProps) => {
	const [isHovered, setIsHovered] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>(null);
	const isInView = useInView(ref, { amount: 0.5, once: true });

	return (
		<Link
			prefetch={false}
			href={`${lng}/case-studies?cat=${tileData.slug.current}` || "#"}
			className={clsx(className && className)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<div
				ref={ref}
				className={clsx(isInView ? "translate-y-0 opacity-100" : "translate-y-20 opacity-0", "w-min duration-500 sm:w-full")}
				style={{ transitionDelay: `${index * 0.1}s` }}
			>
				<div
					className={clsx(
						isHovered ? "border-blue shadow-blueish" : "border-gray-300",
						"aspect-[152/230] h-[25vh] rounded-[0.75rem] border backdrop-blur-xl duration-500 sm:h-auto xs:w-[27.5vw]",
					)}
				>
					<AnimatePresence mode="popLayout">
						{isHovered
							? tileData.hoverIcon && (
									<motion.div
										key={"baseIcon" + index}
										layout
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										transition={{ duration: 0.5 }}
										className="relative h-full w-full"
									>
										<Image
											className={clsx("duration-500")}
											src={urlFor(tileData.hoverIcon).url()}
											alt={caseCategory.title || ""}
											fill
											style={{ objectFit: "contain" }}
											sizes="(max-width: 600px) 30vw, 15vw"
										/>
									</motion.div>
								)
							: tileData.icon && (
									<motion.div
										key={"hoverIcon" + index}
										layout
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										transition={{ duration: 0.5 }}
										className="relative h-full w-full"
									>
										<Image
											className={clsx("duration-500")}
											src={urlFor(tileData.icon).url()}
											alt={caseCategory.title || ""}
											fill
											style={{ objectFit: "contain" }}
											sizes="(max-width: 600px) 30vw, 15vw"
										/>
									</motion.div>
								)}
					</AnimatePresence>
				</div>
				<div className="pt-[1.88rem] text-center text-h6 xs:pt-[1.3rem]">{tileData.title}</div>
			</div>
		</Link>
	);
};

export default BuildingTypeTile;
