"use client";
import { HomePage } from "@/sanity.types";
import { PortableToBlue } from "../../../utility/richTextComponents/richTextComponents";
import { PortableText } from "next-sanity";
import BtnMain from "../../../buttons/BtnMain/BtnMain";
import anim_a from "@assets/lottie/main_A.json";
import anim_b from "@assets/lottie/main_B.json";
import anim_c from "@assets/lottie/main_C.json";
import Image from "next/image";
import mainIlu from "@assets/images/main_ilu.svg";
import dots from "@assets/images/features_dots.svg";
import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import DesktopDescBlock from "./DesktopDescBlock/DesktopDescBlock";
import dynamic from "next/dynamic";
const DynamicLottie = dynamic(() => import("lottie-react"), { ssr: false });

type feature = NonNullable<HomePage["features"]>[number];

const Features = ({ moduleData, lng }: { moduleData: HomePage["features"]; lng: string }) => {
	const sliderContainerRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const [vHeight, setVHeight] = useState<number>(0);
	const [mobileHeadersOffsets, setMobileHeadersOffsets] = useState<number[]>([]);
	const [activeDesktopIndex, setActiveDesktopIndex] = useState<number>(-1);

	useEffect(() => {
		const handleMobileScroll = () => {
			const mobileHeaders = document.querySelectorAll(".mobile-header");
			const mobileOffsets = Array.from(mobileHeaders).map((header) => header.getBoundingClientRect().top - window.innerHeight * 0.7);
			setMobileHeadersOffsets(mobileOffsets);
		};

		window.addEventListener("scroll", handleMobileScroll);

		return () => {
			window.removeEventListener("scroll", handleMobileScroll);
		};
	}, []);

	useEffect(() => {
		setVHeight(window.innerHeight);
	}, []);

	return (
		<>
			{/* Desktop view */}
			<section ref={containerRef} className="grid grid-cols-14 px-grid pt-60 sm:hidden" id="content">
				<div className="col-span-6 h-full">
					<div className="sticky bottom-24 top-[15vh] mb-24 flex aspect-square items-center justify-center">
						<AnimatePresence mode="popLayout">
							{activeDesktopIndex == 0 && (
								<motion.div
									layout
									key="anim_a"
									initial={{ opacity: 0, y: 15 }}
									animate={{ opacity: 1, y: 10 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
									className="relative h-full w-full"
								>
									<DynamicLottie className="h-full w-full" animationData={anim_a} />
								</motion.div>
							)}
							{activeDesktopIndex == 1 && (
								<motion.div
									key="anim_b"
									layout
									initial={{ opacity: 0, y: 15 }}
									animate={{ opacity: 1, y: 10 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
									className="relative h-full w-full"
								>
									<DynamicLottie className="h-full w-full" animationData={anim_b} />
								</motion.div>
							)}
							{activeDesktopIndex == 2 && (
								<motion.div
									key="anim_c"
									layout
									initial={{ opacity: 0, y: 15, x: 4 }}
									animate={{ opacity: 1, y: 11, x: 4 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
									className="relative h-full w-full"
								>
									<DynamicLottie className="h-full w-full" animationData={anim_c} />
								</motion.div>
							)}
						</AnimatePresence>

						<Image
							src={mainIlu.src}
							alt="Main illustration"
							height={mainIlu.height}
							width={mainIlu.width}
							style={{ objectPosition: "top" }}
							className="absolute -z-10"
						/>
						<Image
							src={dots.src}
							alt="Background dots"
							height={dots.height}
							width={dots.width}
							// style={{ objectPosition: "top" }}
							className="absolute -z-20 w-9/12 -translate-x-[7%] translate-y-[16%]"
						/>
					</div>
				</div>
				<div className="col-span-6 col-start-9 flex flex-col gap-y-[19.5rem] pb-[22.5rem] pt-20">
					{moduleData &&
						moduleData.map((feature: feature, i: number) => (
							<DesktopDescBlock key={i} {...feature} lng={lng} index={i} setActiveDesktopIndex={setActiveDesktopIndex} />
						))}
				</div>
			</section>

			{/* Mobile view */}
			<section ref={sliderContainerRef} className="relative mb-[3.75rem] hidden sm:block">
				<div className="grid px-grid-xs">
					<div className="sticky z-30 grid w-full grid-cols-[1fr] grid-rows-[45svh] bg-white pt-16 sm:top-20 xs:top-0">
						<div className="relative z-10 col-start-1 row-start-1 w-[88vw]">
							<Image src={mainIlu.src} alt="" fill style={{ objectFit: "contain" }} className="-z-10" sizes="90vw" />
							<Image src={dots.src} alt="" fill style={{ objectFit: "contain" }} className="-z-20" sizes="90vw" />
						</div>
						<AnimatePresence mode="popLayout">
							{mobileHeadersOffsets[0] <= 0 && mobileHeadersOffsets[1] > 0 && (
								<motion.div
									layout
									key="anim_a"
									initial={{ opacity: 0, y: 15 }}
									animate={{ opacity: 1, y: 10 }}
									exit={{ opacity: 0, y: 15 }}
									transition={{ duration: 0.85 }}
									className="z-20 col-start-1 row-start-1 max-h-full w-full"
								>
									<DynamicLottie className="h-full max-w-full" animationData={anim_a} />
								</motion.div>
							)}
							{mobileHeadersOffsets[1] <= 0 && mobileHeadersOffsets[2] > 0 && (
								<motion.div
									key="anim_b"
									layout
									initial={{ opacity: 0, y: 15 }}
									animate={{ opacity: 1, y: 10 }}
									exit={{ opacity: 0, y: 10 }}
									transition={{ duration: 0.85 }}
									className="z-20 col-start-1 row-start-1 h-full w-full"
								>
									<DynamicLottie className="h-full w-full" animationData={anim_b} />
								</motion.div>
							)}
							{mobileHeadersOffsets[2] <= 0 && mobileHeadersOffsets[2] > vHeight / -2 && (
								<motion.div
									key="anim_c"
									layout
									initial={{ opacity: 0, y: 10 }}
									animate={{ opacity: 1, y: 5 }}
									exit={{ opacity: 0, y: 5 }}
									transition={{ duration: 0.85 }}
									className="z-20 col-start-1 row-start-1 h-full w-full"
								>
									<DynamicLottie className="h-full w-full" animationData={anim_c} />
								</motion.div>
							)}
						</AnimatePresence>
					</div>
					<div className="row-start-2 flex flex-col sm:gap-y-16 sm:px-grid-xs sm:pt-12 xs:px-0 xs:pt-0">
						{moduleData &&
							moduleData.map((feature, i: number) => (
								<div key={i} className="flex flex-col gap-y-2 sm:min-h-[40vh] xs:min-h-0 xs:gap-y-0">
									<h2 className="mobile-header mb-5 sm:text-h2 xs:text-h2-xs">
										{feature && <PortableToBlue value={feature.title} />}
									</h2>
									<div className="mb-5 font-secondary sm:[&_li]:text-h5-xs sm:[&_li]:leading-[150%] xs:[&_li]:text-list-s [&_p]:min-h-4 [&_p]:text-gray-700 sm:[&_p]:text-h5-xs xs:[&_p]:text-base-s [&_ul]:list-inside [&_ul]:list-disc [&_ul]:text-gray-700">
										{feature.description && <PortableText value={feature.description} />}
									</div>
									{feature.cta && (
										<BtnMain href={feature.cta ? `/${lng}/${feature.cta.url}` : "#"} label={feature.cta?.label ?? ""} />
									)}
								</div>
							))}
					</div>
				</div>
			</section>
		</>
	);
};

export default Features;
