"use client";

import clsx from "clsx";
import { useEffect, useState } from "react";

const GoToTop = () => {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	useEffect(() => {
		const handleScroll = () => {
			const scrollY = window.scrollY;
			if (scrollY > 100) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const handleGoToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<div
			onClick={handleGoToTop}
			className={clsx(
				isVisible ? "right-6 opacity-100" : "-right-10 opacity-0",
				"fixed bottom-6 z-40 flex aspect-square h-[2.8rem] cursor-pointer items-center justify-center overflow-hidden rounded-full border border-black bg-black text-white backdrop-blur-[10px] duration-500 ease-out hover:border-blue hover:bg-white/10 hover:text-blue",
			)}
		>
			↑
		</div>
	);
};

export default GoToTop;
