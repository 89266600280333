import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/pages/homePage/BuildingTypes/BuildingTypes.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/pages/homePage/DemoModule/DemoModule.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/pages/homePage/Download/Download.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/pages/homePage/Features/Features.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/pages/homePage/Functions/Functions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/pages/homePage/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/pages/homePage/StatsModule/StatsModule.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/pages/homePage/TechModule/TechModule.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/pages/homePage/Testimonials/Testimonials.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/pages/homePage/VideoModule/VideoModule.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/utility/FloatingContactBtn/FloatingContactBtn.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/utility/GoToTop/GoToTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
