"use client";
import { HomePage } from "@/sanity.types";
import { PortableToBlue } from "../../../utility/richTextComponents/richTextComponents";
import BtnMain from "../../../buttons/BtnMain/BtnMain";
import Image from "next/image";
import { urlFor } from "@/sanity/lib/image";
import { useRef } from "react";
import { useInView } from "framer-motion";
import clsx from "clsx";

const TechModule = ({ moduleData, lng }: { moduleData: HomePage["techModule"]; lng: string }) => {
	const ref = useRef<HTMLDivElement>(null);
	const isInView = useInView(ref, { amount: 0.5, once: true });

	return (
		<section
			ref={ref}
			className={clsx(
				isInView ? "translate-y-0 opacity-100" : "translate-y-20 opacity-0",
				"mx-grid-s mb-8 flex rounded-[1.25rem] bg-gray-100 pb-24 pl-[5.13rem] pr-[5.63rem] pt-[4.8rem] duration-500 sm:flex-col sm:p-12 xs:mx-0 xs:rounded-none xs:px-5 xs:py-10",
			)}
		>
			{moduleData && (
				<>
					<div className="flex grow flex-col gap-y-[2.12rem]">
						<h2 className="text-h2 xs:text-h2-xs">
							<PortableToBlue value={moduleData.title} />
						</h2>
						{moduleData.cta && (
							<BtnMain href={`/${lng}/${moduleData.cta.url}`} label={moduleData.cta.label || ""} className="sm:hidden" />
						)}
					</div>
					<div className="flex w-fit gap-x-[3.12rem] sm:mt-10 sm:gap-x-5">
						{moduleData.values?.map((value, i) => (
							<div key={i} className="flex max-w-[15.6vw] flex-col gap-y-10 sm:max-w-[50vw] sm:grow-0 sm:gap-y-5">
								<Image src={value.icon ? urlFor(value.icon).url() : ""} height={60} width={60} alt={"icon"} />
								<div className="flex flex-col gap-y-4 sm:gap-y-2">
									<div className="font-secondary-semibold text-h6 sm:w-10/12">{value.title}</div>
									<p className="font-secondary text-base-s text-gray-700">{value.description}</p>
								</div>
							</div>
						))}
					</div>
					{moduleData.cta && (
						<BtnMain
							href={`/${lng}/${moduleData.cta.url}`}
							label={moduleData.cta.label || ""}
							className="mt-10 hidden sm:block"
						/>
					)}
				</>
			)}
		</section>
	);
};

export default TechModule;
