import { defineField, defineType } from "sanity";

export default defineType({
	name: "caseCategory",
	title: "Category",
	type: "document",
	fields: [
		defineField({
			name: "language",
			type: "string",
			readOnly: true,
			hidden: true,
		}),
		defineField({
			name: "title",
			title: "Title",
			type: "string",
			validation: (Rule) => Rule.required(),
		}),
		defineField({
			name: "slug",
			title: "Slug",
			type: "slug",
			validation: (Rule) => Rule.required(),
			options: {
				source: "title",
			},
		}),
		defineField({
			name: "icon",
			title: "Base Icon",
			type: "image",
			validation: (Rule) => Rule.required(),
		}),
		defineField({
			name: "hoverIcon",
			title: "Hover Icon",
			description: "Should be the same icon but in blue",
			type: "image",
			validation: (Rule) => Rule.required(),
		}),
	],
	preview: {
		select: {
			title: "title",
			subtitle: "language",
		},
		prepare({ title, subtitle }) {
			return {
				title: title,
				subtitle: subtitle.toUpperCase() || "No language set",
			};
		},
	},
});
