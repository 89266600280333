import Image, { StaticImageData } from "next/image";
import { Suspense } from "react";

const VideoContainer = ({ url, fallback }: { url: string; fallback: StaticImageData }) => {
	return (
		<Suspense fallback={<Image src={fallback.src} alt="video" fill objectFit="contain" />}>
			<video autoPlay loop muted playsInline className="h-full object-contain">
				<source src={url} type="video/webm" />
				<source src={url} type="video/mp4" />
			</video>
		</Suspense>
	);
};

export default VideoContainer;
