"use client";
import { HomePage } from "@/sanity.types";
import TestimonialsWrapper from "./TestimonialsWrapper/TestimonialsWrapper";
import { useScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import Image from "next/image";
import dots from "@assets/images/testimonials_dots.svg";
import { KeenSliderInstance, useKeenSlider } from "keen-slider/react";

const Testimonials = ({ moduleData }: { moduleData: HomePage["testimonialsModule"] }) => {
	const ref = useRef<HTMLDivElement>(null);
	const { scrollYProgress } = useScroll({ target: ref, offset: ["start end", "end start"] });
	const [sliderRef, instanceRef] = useKeenSlider({ slides: { spacing: 20 }, loop: true });
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	useEffect(() => {
		const handleSlideChange = (slider: KeenSliderInstance) => {
			setActiveSlideIndex(slider.track.details.rel);
		};

		if (instanceRef.current) {
			instanceRef.current.on("slideChanged", handleSlideChange);
		}
	}, [instanceRef]);

	return (
		<>
			{/* // Desktop version */}
			<section
				ref={ref}
				className="relative isolate grid aspect-[1000/636] w-screen grid-cols-14 bg-gray-200 pl-grid before:absolute before:top-0 before:z-20 before:h-36 before:w-full before:bg-gradient-to-b before:from-gray-200 before:to-transparent after:absolute after:bottom-0 after:z-20 after:h-36 after:w-full after:bg-gradient-to-t after:from-gray-200 after:to-transparent sm:hidden"
			>
				<div className="relative col-span-4">
					<div className="sticky top-32 mt-32 flex flex-col gap-y-12">
						<h3 className="w-10/12 text-h3">{moduleData?.title}</h3>
						<p className="w-8/12 font-secondary text-base text-gray-800">{moduleData?.description}</p>
					</div>
				</div>
				<div className="col-span-full col-start-7 h-full overflow-hidden">
					<TestimonialsWrapper testimonials={moduleData?.testimonials} scrollYProgress={scrollYProgress} />
				</div>
				<Image
					src={dots.src}
					alt="dots"
					className="absolute right-1/2 top-1/2 -z-10 -translate-y-1/4 translate-x-1/2"
					height={dots.height}
					width={dots.width}
				/>
			</section>

			{/* // Mobile version */}
			<section className="hidden flex-col bg-gray-200 px-grid-xs py-[3.75rem] sm:flex">
				<div className="mb-[3.75rem]">
					<h3 className="text-h4 xs:text-h4-xs">{moduleData?.title}</h3>
					<p className="mt-4 w-10/12 font-secondary text-base text-gray-800 xs:text-base-s">{moduleData?.description}</p>
				</div>
				<div className="relative isolate">
					<div className="mb-[3.5rem] mt-8 flex flex-col items-center">
						<div ref={sliderRef} className="keen-slider">
							{moduleData &&
								moduleData?.testimonials?.map((testimonial, index) => (
									<div
										key={index}
										className="keen-slider__slide flex flex-col justify-between rounded-[1.25rem] bg-white sm:aspect-auto sm:p-10 xs:aspect-square xs:p-6"
									>
										<p className="font-secondary leading-[150%] text-gray-900 sm:text-base xs:text-[0.75rem]">
											{testimonial.text}
										</p>
										<div className="flex flex-col font-secondary leading-[130%] sm:pt-12 sm:text-base xs:pt-0 xs:text-base-s">
											<p className="font-secondary-bold">{testimonial.author}</p>
											<p className="text-[#111010] opacity-50">{testimonial.position}</p>
										</div>
									</div>
								))}
						</div>
						<div className="relative mt-[3.5rem] h-px w-7/12 bg-[#C1C1C1]">
							<div
								className="h-2 rounded-[0.625rem] bg-black duration-300"
								style={{
									width: `${100 / (moduleData?.testimonials?.length ?? 5)}%`,
									transform: `translate(${activeSlideIndex * 100}%, -50%)`,
								}}
							></div>
						</div>
					</div>
					{/* <Image src={dots.src} fill alt="" className="-z-10" style={{ objectFit: "cover" }} sizes="100vw" /> */}
				</div>
			</section>
		</>
	);
};

export default Testimonials;
