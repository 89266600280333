"use client";
import step1 from "@assets/lottie/step_1.json";
import step2 from "@assets/lottie/step_2.json";
import step3 from "@assets/lottie/step_3.json";
import step4 from "@assets/lottie/step_4.json";

import clsx from "clsx";
import { AnimatePresence, motion, useMotionValueEvent, useScroll, useSpring } from "framer-motion";
import { LottieRefCurrentProps } from "lottie-react";
import { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
const DynamicLottie = dynamic(() => import("lottie-react"), { ssr: false });

const StepMobileTile = ({ tileData, index, imgContainerClass }: { tileData: string; index: number; imgContainerClass?: string }) => {
	const lottieRef = useRef<LottieRefCurrentProps | null>(null);
	const targetRef = useRef<HTMLDivElement>(null);
	const [isActive, setIsActive] = useState<boolean>(false);
	const { scrollYProgress } = useScroll({ target: targetRef, offset: ["start center", "end center"] });
	const smoothYProgress = useSpring(scrollYProgress, { stiffness: 120, damping: 20, mass: 1.25 });

	useMotionValueEvent(scrollYProgress, "change", (latest) => {
		if (latest > 0.05 && latest < 0.95) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	});

	useEffect(() => {
		lottieRef.current?.stop();
		if (isActive) {
			lottieRef.current?.play();
		} else {
			lottieRef.current?.stop();
		}
	}, [isActive]);

	const getLottieToIndex = (index: number) => {
		switch (index) {
			case 0:
				return step1;
			case 1:
				return step2;
			case 2:
				return step3;
			case 3:
				return step4;
			default:
				return step1;
		}
	};

	const defineOffsetClass = (index: number) => {
		switch (index) {
			case 0:
				return "-mt-4";
			case 1:
				return "translate-x-6";
			case 2:
				return "p-3 -mt-2";
			case 3:
				return "-mt-4";
			default:
				return "-mt-10";
		}
	};

	return (
		<div className="max-w-screen flex" ref={targetRef}>
			<div className="relative isolate flex justify-center">
				<div
					className={clsx(
						isActive ? "bg-blue" : "bg-gray-400",
						"flex aspect-square h-10 items-center justify-center rounded-full text-white duration-500",
					)}
				>
					{index + 1}
				</div>
				<motion.div
					className="absolute bottom-0 top-0 -z-10 w-px origin-top bg-blue"
					style={{ scaleY: smoothYProgress }}
				></motion.div>
				<AnimatePresence>
					{index + 1 == 4 && scrollYProgress.get() >= 0.95 && (
						<motion.div
							layout
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.5 }}
							className="absolute bottom-0 left-1/2 flex aspect-square h-5 -translate-x-1/2 items-center justify-center rounded-full bg-black"
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" fill="none">
								<path d="M1 3.30769L4.42857 7L10 1" stroke="#F3F3F3" strokeWidth="2" />
							</svg>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
			<div className="grid">
				<div className="mb-10 px-5 sm:mb-24">
					<p
						className={clsx(
							isActive ? "translate-y-0 opacity-100" : "translate-y-5 opacity-0",
							"mb-8 px-2 text-center text-base leading-[140%] duration-500 sm:text-h5-xs xs:text-base",
						)}
					>
						{tileData}
					</p>

					<div className="px-10">
						<div
							className={clsx(
								isActive ? "border-blue bg-white shadow-blueish" : "border-[#D9D9D9]",

								"relative aspect-square rounded-[1.25rem] border duration-500",
							)}
						>
							<DynamicLottie
								autoPlay={false}
								lottieRef={lottieRef}
								animationData={getLottieToIndex(index)}
								className={clsx(imgContainerClass, defineOffsetClass(index), "aspect-square max-h-full")}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StepMobileTile;
